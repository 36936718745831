import React, { useEffect } from "react"
import PropTypes from "prop-types"
// import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
// import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
// import HeaderHeroLayout from "./HeaderHeroLayout"
import HeaderHeroLayout from './MaintenanceHeaderHeroLayout'
import MaintenanceVideo from "../assets/images/maintenancevid.mp4"
import MaintenanceVidPlaceholder from "../assets/images/maintenancevidplaceholder.jpg"
import Hidden from "@material-ui/core/Hidden"
import headerImage from '../assets/images/Pond_Maintence_Skimmer_Mat_Andreatta_Waterscapes_Central_Point_Oregon.jpg'

const styles = theme => ({
  button: {
    height: "3.275rem",
    fontFamily: "Roboto, Helvetica Neue",
    fontSize: "1.25rem",
    fontWeight: 600,
    minWidth: 300,
    transition: "0.3s",
    "& :hover": {
      color: "#FFFFFF",
      // opacity: 0.8,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      minWidth: 150,
    },
  },
  h1: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "2.5rem",
    fontWeight: 400,
    lineHeight: "1.2",
    letterSpacing: 3,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      paddingTop: '30%',
    },
  },
  h2: {
    fontFamily: "Work Sans, sans-serif",
    // color: theme.palette.common.white,
    // fontFamily: "Work Sans, sans-serif",
    // fontWeight: 400,
    // fontSize: "1.2375rem",
    // [theme.breakpoints.down("xs")]: {
    //   fontSize: "1.05rem",
    //   lineHeight: "1.3rem",
    // },
  },

  h5: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  dynamicBackground: {
    // backgroundImage: `url(${MaintenanceVidPlaceholder})`,
    backgroundImage: `url(${headerImage})`,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // background: "#00171E",
    // mixBlendMode: "overlay",
  },
  dynamicVideo: {
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    opacity: 1,
  },
})

function MaintenanceHero(props) {
  const { classes } = props
  const [initiated, setInitiated] = React.useState(false)

  useEffect(() => {
    setInitiated(true)
  }, [])

  return (
    <HeaderHeroLayout
      arrow={false}
      backgroundClassName={classes.dynamicBackground}
    >
      {/* <img
        src={MaintenanceVidPlaceholder}
        style={{ display: "none" }}
        alt="increase priority"
      /> */}
      {/* Increase the network loading priority of the background image. */}

      {/* {!initiated && (
        <img
          src={MaintenanceVidPlaceholder}
          alt="Waterfall Placeholder"
          className={classes.dynamicVideo}
        />
      )}
      <Hidden xsDown>
        <video
          src={MaintenanceVideo}
          muted
          loop
          autoPlay
          playsInline
          className={classes.dynamicVideo}
        />
      </Hidden> */}
      {/* <Hidden smUp> */}
        {/* <img
          src={MaintenanceVidPlaceholder}
          alt="waterfall"
          className={classes.dynamicVideo}
        /> */}
        {/* <video
          src={MaintenanceVideo}
          muted
          loop
          autoPlay
          playsInline
          className={classes.dynamicVideo}
        /> */}
      {/* </Hidden> */}
      <Typography
        color="inherit"
        align="center"
        variant="h1"
        marked="center"
        className={classes.h1}
      >
        MAINTENANCE SERVICES
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="body1"
        marked="center"
        className={classes.h2}
      >
        Southern Oregon Pond Cleaning & Maintenance
      </Typography>
      {/* 
      <Button
        color="primary"
        variant="outlined"
        size="large"
        className={classes.button}
        component={Link}
        to="/contact/"
      >
        Request a Quote
      </Button> */}
    </HeaderHeroLayout>
  )
}

MaintenanceHero.propTypes = {
  classes: PropTypes.object.isRequired,
  dynamic: PropTypes.string,
}

export default withStyles(styles)(MaintenanceHero)
