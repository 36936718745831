import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
// import { useLocation } from "@reach/router"
// import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import { makeStyles } from "@material-ui/core/styles"
import { alpha } from "@material-ui/core/styles/colorManipulator"
import Container from "@material-ui/core/Container"
// import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
// import List from "@material-ui/core/List"
// import ListItem from "@material-ui/core/ListItem"
// import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
// import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import PhotoGallery from './templates/ServicePageGallery'
// import useMediaQuery from "@material-ui/core/useMediaQuery"
// import SidebarTestimonials from "./UI/SidebarTestimonials"

const query = graphql`
  {
    contentfulMaintenancePageGallery {
      images {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        title
        id
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  h3: {
    fontSize: "2rem",
    fontFamily: "Open Sans, Work Sans, sans-serif",
    // color: theme.palette.common.muted,
    color: theme.palette.secondary.light,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.35rem",
    },
  },
  h4: {
    color: theme.palette.secondary.light,
    fontFamily: "Work Sans, sans-serif",
    fontSize: "1.75rem",
    fontWeight: 400,
    marginBottom: ".35em",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.65rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  h5: {},
  body: {
    fontSize: "1.05rem",
    lineHeight: "1.75rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: ".95rem",
    },
  },
  preHeader: {
    fontWeight: 500,
    marginBottom: ".25rem",
    color: "#646e73",
    fontSize: "1rem",
    textTransform: "uppercase",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    opacity: 0.8,
  },
  hr: {
    marginTop: ".6em",
    backgroundColor: theme.palette.background.paper,
    width:"85%",
    // maxWidth: "80%",
    marginBottom: "1rem",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    // borderColor: theme.palette.primary.main,
    borderColor: alpha(theme.palette.secondary.light, 0.6),
    // [theme.breakpoints.only("xl")]: {
    //   maxWidth: "65%",
    // },
    // [theme.breakpoints.only("xs")]: {
    //   maxWidth: "100%",
    // },
    [theme.breakpoints.only("xs")]: {
      width: "98.5%",
    },
  },
  paper: {
    borderRadius: 0,
    border: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
    padding: "1rem",
  },
  cardTitle: {
    fontSize: "1.75rem",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
    fontWeight: 600,
    textDecoration: "none",
    transition: "0.5s",
    "&:hover": {
      color: "#000",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.45rem",
    },
  },
  contactBtn: {
    borderRadius: 0,
    fontSize: "1.1rem",
    padding: ".5rem 2rem",
    width: "100%",
    transition: "0.1s",
    "&:hover": {
      color: "#000",
      backgroundColor: theme.palette.primary.main,
      transform: "scale(1.1)",
    },
  },
  contactSection: {
    marginTop: "1rem",
    borderRadius: 0,
    border: `3px solid ${theme.palette.secondary.main}`,
    padding: "2rem 4rem",
    backgroundColor: "transparent",
    lineHeight: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 2rem",
    },
  },
  contactSecText: {
    fontWeight: 500,
    fontSize: "1.1rem",
    color: theme.palette.common.muted,
  },
  promoOfferPaper: {
    borderRadius: 0,
    padding: ".5rem",
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-1.5rem",
      marginRight: "-1.5rem",
    },
  },
  promoBorder: {
    padding: "2.5rem",
    border: `3px solid ${theme.palette.common.muted}`,
    borderStyle: "dashed",
  },
  promoHeader: {
    color: theme.palette.common.muted,
    fontWeight: 700,
    fontSize: "2.5rem",
    lineHeight: "1",
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
    },
  },
  promoSubHeader: {
    color: theme.palette.common.muted,
    fontWeight: 600,
    fontSize: "1.5rem",
  },
  promoButton: {
    marginTop: "1rem",
    width: "100%",
    height: "3.125rem",
    color: "#FFFFFF",
    borderRadius: 0,
    backgroundColor: theme.palette.common.muted,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.9)",
    },
  },
  textField: {
    width: "100%",
    borderRadius: 0,
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
    },
  },
  floatLeft: {
    [theme.breakpoints.only("xl")]: {
      paddingLeft: "15rem",
    },
    [theme.breakpoints.only("lg")]: {
      paddingLeft: "5rem",
    },
  },
  floatRight: {
    [theme.breakpoints.only("xl")]: {
      marginLeft: "-2rem",
    },
    [theme.breakpoints.only("lg")]: {
      marginLeft: "-2rem",
    },
  },
  li: {
    ...theme.typography.body1,
    fontSize: '.905rem',
    padding: '.10rem',
    '& span': {
      fontWeight: 600,
    }
  },
}))

// const StyledListItem = withStyles(theme => ({
//   root: {
//     transition: "transform 0.5s",
//     textDecoration: "none",
//     "&:hover": {
//       backgroundColor: theme.palette.secondary.main,
//       color: "#fff",
//       transform: "scale(1.025)",
//     },
//     "&.Mui-selected": {
//       backgroundColor: "#000",
//       color: "#fff",
//       fontSize: "1.01rem",
//     },
//     "&.Mui-selected:hover": {
//       backgroundColor: "#000",
//       color: "#fff",
//       transform: "scale(1.025)",
//     },
//   },
// }))(ListItem)

export default function MaintenanceVersionTwo() {
  // const theme = useTheme()
  const classes = useStyles()
    const {
      contentfulMaintenancePageGallery: { images: imageList },
    } = useStaticQuery(query)
  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  // const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  // const matchesXL = useMediaQuery(theme.breakpoints.up("xl"))
  // const location = useLocation()

  // let CustomListItem = ({ to, primary }) => (
  //   <>
  //     <StyledListItem
  //       button
  //       component={Link}
  //       to={to}
  //       selected={to === location.pathname}
  //     >
  //       <ListItemText primary={primary} />
  //     </StyledListItem>
  //     <Divider />
  //   </>
  // )

  let ItemSpacer = () => (
    <Grid item xs={12} style={{ paddingBottom: "1rem" }}>
      <Grid container>
        <Divider
          style={{
            width: "90%",
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
    </Grid>
  )

  return (
    <>
      <Container maxWidth="xl" style={{ paddingBottom: "5rem" }}>
        <Grid container>
          {/* <Grid item xs={12} md={8}> */}
          <Grid item xs={12}>
            <Container maxWidth="lg">
              <Grid
                container
                style={{ paddingTop: "4rem" }}
                className={classes.floatLeft}
              >
                {/* <Grid item xs={12} lg={10} xl={11}> */}
                <Grid item xs={12}>
                  <Container maxWidth="lg">
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h3" className={classes.h3}>
                          Maintenance plans for any pond
                        </Typography>
                        <Divider className={classes.hr} />
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
                <Grid item xs={12} lg={11} xl={11}>
                  <Container maxWidth="lg">
                    <Grid container>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          All water features will require some sort of
                          maintenance. For some it will be very minimal, such as
                          for patio ponds and fountains. For goldfish ponds, koi
                          ponds and water gardens, there will be higher levels
                          of maintenance required.
                        </Typography>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          Whether you decide to do it yourself or hire us to
                          provide your pond maintenance services, we’ll make
                          sure you know exactly what needs to be done to keep
                          your pond healthy and beautiful.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Spring Cleanouts{" "}
                          <span
                            style={{
                              fontSize: "65%",
                              fontWeight: 400,
                              fontFamily: "Open Sans",
                              fontStyle: "italic",
                            }}
                          >
                            - Starting at $500
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          An annual spring cleanout is an important service in
                          getting your pond ready for the coming warm weather.
                          We begin cleanouts in February and usually wrap up the
                          clean out season by mid-April.
                        </Typography>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          A complete pond cleanout can be a large and messy job,
                          so many of our customers prefer to have us provide
                          this service rather than doing it themselves. We do
                          recommend an annual cleanout so that organic matter
                          doesn’t build up in the bottom of your pond, which can
                          cause problems.
                        </Typography>
                        <Typography variant="body1">
                          A spring cleanout consists of the following service:
                        </Typography>
                        <ul style={{ marginBottom: "1rem" }}>
                          <li className={classes.li}>
                            <span>Test water quality</span>
                          </li>
                          <li className={classes.li}>
                            <span>Move koi and goldfish to large tubs.</span> We
                            take great care in making sure your fish are
                            protected during this process.
                          </li>
                          <li className={classes.li}>
                            <span>Drain the pond.</span> In order to conserve
                            water, we use a portion of the old water for the
                            cleanout itself and will also return a portion of
                            the original water in order to reseed the freshly
                            cleaned pond with the beneficial bacteria that
                            already existed in your ecosystem.
                          </li>
                          <li className={classes.li}>
                            <span>
                              Scrub and/or power wash waterfall, stream, sides
                              and bottom of pond
                            </span>
                          </li>
                          <li className={classes.li}>
                            <span>
                              Remove debris and sediment from the bottom of the
                              pond, streams and waterfall pools
                            </span>
                          </li>
                          <li className={classes.li}>
                            <span>
                              Clean filtrations system and replace
                              skimmer/waterfall mats as needed
                            </span>
                          </li>
                          <li className={classes.li}>
                            <span>Inspect for leaks</span>
                          </li>
                          <li className={classes.li}>
                            <span>
                              Inspect lights, pump, autofill, and skimmer to
                              ensure they are working properly.{" "}
                            </span>{" "}
                            If any repairs are needed, we will either complete
                            them at the time of the cleanout if possible, or
                            make recommendations for additional repairs or
                            upgrades.
                          </li>
                          <li className={classes.li}>
                            <span>Trim aquatic plants </span>and add new aquatic
                            plants as desired by customer.
                          </li>
                          <li className={classes.li}>
                            <span>Refill pond and move fish back in</span>
                          </li>
                          <li className={classes.li}>
                            <span>Add needed product </span>such as beneficial
                            bacteria and others to help balance any deficiencies
                            found during the water test.
                          </li>
                        </ul>
                        <Typography variant="body1" paragraph>
                          *In order to protect koi, we only offer pond cleanouts
                          during the cooler spring weather.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>

                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Bi-weekly Maintenance{" "}
                          <span
                            style={{
                              fontSize: "65%",
                              fontWeight: 400,
                              fontFamily: "Open Sans",
                              fontStyle: "italic",
                            }}
                          >
                            - Starting at $150/Visit
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          Our pond maintenance service allows pond owners to
                          enjoy the beauty of their water feature without the
                          need to spend time on its upkeep. Some ponds may only
                          require monthly service, but we find that most benefit
                          from having maintenance performed twice per month.
                        </Typography>
                        <Typography variant="body1">
                          Bi-weekly pond maintenance consists of the following
                          services:
                        </Typography>
                        <ul style={{ marginBottom: "1rem" }}>
                          <li className={classes.li}>
                            <span>
                              Removal of surface and floating debris such as
                              leaves and algae using pond nets and/or pond
                              vacuum
                            </span>
                          </li>
                          <li className={classes.li}>
                            <span>Cleaning of skimmer net and mats</span>
                          </li>
                          <li className={classes.li}>
                            <span>
                              Clean out pump and skimmer box of any accumulated
                              debris
                            </span>
                          </li>
                          <li className={classes.li}>
                            <span>
                              Brush scrubbing of rocks in pond, stream and
                              waterfall
                            </span>
                          </li>
                          <li className={classes.li}>
                            <span>Trim aquatic plants as necessary</span>
                          </li>
                          <li className={classes.li}>
                            <span>
                              Addition of needed product to keep pond water
                              properly balanced
                            </span>
                          </li>
                        </ul>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Biological Filter Maintenance{" "}
                          <span
                            style={{
                              fontSize: "65%",
                              fontWeight: 400,
                              fontFamily: "Open Sans",
                              fontStyle: "italic",
                            }}
                          >
                            - Starting at $150/Visit
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                        >
                          We provide pond owners with a quarterly, or as needed,
                          service for cleaning the biological filters normally
                          found in the biofalls or waterfall box above the pond.
                        </Typography>
                        <ul style={{ marginBottom: "1rem" }}>
                          <li className={classes.li}>
                            <span>
                              Remove algae and other debris that may have built
                              up
                            </span>
                          </li>
                          <li className={classes.li}>
                            <span>Clean biological filter mats</span>
                          </li>
                          <li className={classes.li}>
                            <span>Replace degraded or damaged mats</span>
                          </li>
                        </ul>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          className={classes.h4}
                          paragraph
                        >
                          Fall Cleanup & Netting{" "}
                          <span
                            style={{
                              fontSize: "65%",
                              fontWeight: 400,
                              fontFamily: "Open Sans",
                              fontStyle: "italic",
                            }}
                          >
                            - Starting at $300/Visit
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          Preparing your pond for winter is an important step in
                          keeping your pond and fish healthy. Getting out the
                          leaves and other matter that fall into the pond during
                          the autumn months will prevent having a bigger mess to
                          deal with in the spring.
                        </Typography>
                        <Typography
                          variant="body1"
                          // className={classes.body}
                          paragraph
                        >
                          A good choice to reduce the dump of leaves into the
                          pond is to place a net over the pond until the leaves
                          stop falling. We sell nets that you can place
                          yourself, or we can come install it for you. After the
                          trees are bare, the net can be removed and the leaves discarded.
                        </Typography>
                      </Grid>
                      <ItemSpacer />
                    </Grid>
                    <Grid container style={{ paddingTop: "5rem" }} justifyContent='center'>
                      <Paper className={classes.card} elevation={10}>
                        <StaticImage src="../assets/images/Pond-Spring-Cleanout_Andreatta-Waterscapes_Medford-Oregon.jpg"
                        layout="constrained" alt="Pond Spring Cleanout Medford Oregon Andreatta Waterscapes" placeholder="blurred"
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'flex',
                          alignItems:'center',
                        }}
                        imgStyle={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: 2,
                        }}
                        />
                        {/* <StaticImage
                          src="../assets/images/andreattawaterscapesmaintenanceservices.jpg"
                          height={450}
                          quality={95}
                          layout="constrained"
                          alt="Water Quality Test"
                          placeholder="blurred"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            alignItems: "center",
                          }}
                          imgStyle={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 2,
                          }}
                        /> */}
                      </Paper>
                      <Grid item xs={12}>
                        <PhotoGallery imageList={imageList} />
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Container
              maxWidth={matchesMD ? "xl" : "xs"}
              style={{
                paddingTop: "6rem",
                paddingBottom: matchesSM ? "5rem" : undefined,
              }}
              className={classes.floatRight}
            >
              <Grid container direction="row" justifyContent="center">
                 <Grid item xs={12}>
                  <Paper className={classes.paper} elevation={20}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          component={Link}
                          to="/new-installations/"
                          className={classes.cardTitle}
                          gutterBottom
                        >
                          Our Waterscape Services
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "1rem" }}>
                        <List
                          component="nav"
                          aria-label="secondary mailbox folders"
                          style={{ margin: "-1rem" }}
                        >
                          <Divider />
                          <CustomListItem
                            to="/new-installations/"
                            primary="New Installations"
                          />
                          <CustomListItem
                            to="/repairs-renovations/"
                            primary="Repairs & Renovations"
                          />
                          <CustomListItem
                            to="/maintenance/"
                            primary="Maintenance"
                          />
                          <CustomListItem
                            to="/outdoor-living/"
                            primary="Outdoor Living"
                          />
                        </List>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid> 
                 <Grid item xs={12} style={{ marginTop: "3rem" }}>
                  <Paper className={classes.promoOfferPaper} elevation={0}>
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.promoBorder}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          align="center"
                          className={classes.promoHeader}
                        >
                          FREE Download
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.promoSubHeader}
                          paragraph
                          align="center"
                        >
                          HOW TO MAINTAIN YOUR BACKYARD POND
                        </Typography>
                        <form>
                          <input
                            type="hidden"
                            name="form-name"
                            value="Newsletter Signup"
                          />

                          <TextField
                            className={classes.textField}
                            placeholder="Your email"
                            variant="outlined"
                            id="Email Address"
                            name="Email Address"
                          />
                          <Button
                            type="submit"
                            color="inherit"
                            variant="contained"
                            disableElevation={true}
                            className={classes.promoButton}
                          >
                            Get it!
                          </Button>
                        </form>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid> 
                <Grid item xs={12}>
                  <SidebarTestimonials />
                </Grid>
                 <Grid item xs={12} style={{ marginTop: "3rem" }}>
                  <Grid container justifyContent="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      component={Link}
                      to="/contact/"
                      className={classes.contactBtn}
                    >
                      Contact Today
                    </Button>
                  </Grid>
                </Grid> 
              </Grid>
            </Container>
          </Grid> */}
        </Grid>
      </Container>
    </>
  )
}
