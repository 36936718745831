import React from "react"

import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/DynamicNavbar"
import Seo from "../components/Seo"
// import MaintenanceDescription from "../components/MaintenanceDescrip"
// import MaintenanceProgramTable from "../components/MaintenanceProgramTable"
// import CallToAction from "../components/CallToAction"
// import MaintenanceHero from "../components/MaintenanceHeaderHero"
import MaintenanceHeaderHero from "../components/MaintenanceHeaderHero"
import MaintenanceVersionTwo from "../components/MaintenanceVersionTwo"
// import NewsletterSignup from "../components/NewsletterSignup"
// import PricingCTA from "../components/UI/PricingCTA"
import ContactBtn from "../components/IndexContactBtn"

export default function MaintenancePage() {
  return (
    <Layout>
      <Navbar />
      <Seo
        title="Maintenance Services"
        description="Learn about professional maintenance services for ponds and other water features by Andreatta Waterscapes."
      />
      <MaintenanceHeaderHero />
      <MaintenanceVersionTwo />
      {/* <PricingCTA /> */}
      {/* <MaintenanceDescription /> */}
      {/* <MaintenanceProgramTable /> */}
      {/* <CallToAction end={true} /> */}
      <ContactBtn />
    </Layout>
  )
}
