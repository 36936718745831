import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import clsx from "clsx"
import { withStyles, useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Fade from "@material-ui/core/Fade"
// import HeaderHeroWonder from "../assets/images/HeaderHeroWonder.png"
import HeaderHeroArrowDown from "../assets/images/HeroHeaderArrowDown.png"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const styles = theme => ({
  root: {
    color: theme.palette.common.white,
    position: "relative",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      height: "70vh",
      minHeight: 500,
      maxHeight: 1300,
      marginTop: "-5em",
    },
    [theme.breakpoints.down("sm")]: {
      height: "70vh",
      minHeight: 500,
      marginTop: "-7em",
    },
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(14),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "#00171E",
    opacity: 0.1,
    zIndex: -1,
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
  },
  arrowDown: {
    position: "absolute",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    bottom: theme.spacing(4),
    cursor: "pointer",
  },
})

function HeaderHeroLayout(props) {
  const { backgroundClassName, children, classes } = props
  const [offset, setOffset] = useState(0)
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
    return () => {
      setOffset(null)
    }
  }, [])
  const handleClick = () => {
    if (matchesXS) {
      window.scrollTo({
        top: 450,
        behavior: "smooth",
      })
    } else {
      window.scrollTo({
        top: 575,
        behavior: "smooth",
      })
    }
  }

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        {/* {props.homePage && (
          <img src={HeaderHeroWonder} alt="wonder" width="147" height="80" />
        )} */}
        {children}
        <div className={classes.backdrop} />
        <div className={clsx(classes.background, backgroundClassName)} />
        {props.arrow === false ? (
          ""
        ) : (
          <Fade in={offset < 400}>
            <div
              role="button"
              onClick={handleClick}
              tabIndex="0"
              onKeyPress={handleClick}
            >
              <img
                className={classes.arrowDown}
                src={HeaderHeroArrowDown}
                height="45"
                width="39"
                alt="arrow down indicator"
              />
            </div>
          </Fade>
        )}
      </Container>
    </section>
  )
}

HeaderHeroLayout.propTypes = {
  backgroundClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(HeaderHeroLayout)
